@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Tajawal";
}

html {
  background: black;
  width: 100%;
  overflow-x: hidden;
}

.App {
  overflow-x: hidden;
}

.sq-container {
  display: grid;
  grid-template-rows: repeat(100, 1fr);
  grid-template-columns: repeat(50, 1fr);
  margin: auto;
}

.outer-sqaure {
  background: transparent;
  box-shadow: 0px 0px 20px white;
}

.hover-over {
  animation: colorRotate 3s linear forwards;
}

.outline-box {
  animation: borderOutline 2s linear forwards;
}

@keyframes colorRotate {
  0% {
    background: transparent;
  }

  25% {
    background: rgb(23, 108, 165);
  }

  50% {
    background: rgb(21, 68, 122);
  }

  75% {
    background: rgb(31, 50, 90);
  }

  100% {
    background: transparent;
  }
}

@keyframes borderOutline {
  0% {
    border: 3px solid rgba(21, 68, 122, 0.8);
    opacity: 0;
  }

  50% {
    border: 3px solid rgba(21, 68, 122, 0.8);
    opacity: 1;
  }

  100% {
    border: 3px solid rgba(21, 68, 122, 0.8);
    opacity: 0;
  }
}

.stand-out {
  text-shadow: 0 0 2px #eee;
}

.active-tab {
  border-bottom: 2px solid gold;
}

/* burger menu */

.burger-icon {
  height: 30px;
  width: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line {
  height: 3px;
  width: 100%;
  background: white;
}

.burger-menu {
  transition: all 1s ease-in-out;
  border-left: 2px solid gold;
  left: 100%;
}

.show-menu {
  transform: translateX(-100%);
}

/* hero section */

.hero-msg {
  font-size: 30px;
  box-shadow: 0px 10px 0px rgba(8, 100, 136, 0.3);
  line-height: 32px;
}

.hero-msg-bg {
  background: rgba(8, 100, 136, 0.3);
}

.hero-img-wrapper {
  max-width: 150px;
}

.hero-img {
  filter: contrast(1.6);
}

.arrow-down-symbol {
  animation: bounce 2s linear infinite;
  position: relative;
}

@keyframes bounce {
  0% {
    top: 0px;
  }

  25% {
    top: -15px;
  }

  50% {
    top: 0px
  }

  75% {
    top: 15px
  }

  100% {
    top: 0px;
  }
}

/* music player */
#track-img-focus {
  background-color: rgb(3, 105, 161);
  opacity: 1;
}

#track-img-focus .track-img-wrapper {
  height: 200px;
  width: 200px;
}

.track-visual {
  opacity: 0.7;
}

.track-img-wrapper {
  transition: all 0.75s ease;
}

.track-img-reel {
  transition: all 1s ease;
}

/* form */
.contact-form {
  box-shadow: 20px 20px 0px rgba(21, 68, 122, 0.3);
}

.label {
  color: white;
  margin-bottom: 4px;
}

.form-input {
  height: 40px;
  width: 100%;
  border-radius: 3px;
  outline: none;
  border: none;
  padding: 5px 15px;
  font-size: 15px;
}

textarea {
  height: 160px;
  width: 100%;
  border-radius: 6px;
  outline: none;
  border: none;
  padding: 5px 15px;
  font-size: 15px;
}

.loader {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(720deg);
  }
}

/* general */
.main-heading {
  box-shadow: -8px 0px 0px rgb(20, 63, 92);
}

/* tech */

.tech-item {
  filter: grayscale(100%);
  transition: all 0.15s ease;
}

.tech-item-active {
  filter: grayscale(0%);
}

/* media queries */

@media screen and (min-width: 768px) {
  .sq-container {
    display: grid;
    grid-template-rows: repeat(100, 1fr);
    grid-template-columns: repeat(100, 1fr);
    margin: auto;
  }
}

@media screen and (min-width: 1400px) {
  .sq-container {
    display: grid;
    grid-template-rows: repeat(400, 1fr);
    grid-template-columns: repeat(200, 1fr);
    margin: auto;
  }

  .burger-menu {
    border-left: none;
  }
}